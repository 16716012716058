<template>
  <div class="placePage">
    <div class="mainContainer">
      <TopMenu :show-logo="true"/>
      <div class="infoSection" v-if="!state.isLoading">
        <div class="thumb">
          <DuPhotoViewer :thumb="createPhotoLink(state.photos[0], 200, 200)"  :fullImage="createPhotoLink(state.photos[0])" width="100%" height="100%" :listOfLinks="getListOfLinks()"/>
        </div>
        <div class="infoSide">
          <div class="placeName">{{state.data.place_name}}</div>
          <div class="infoCel">
            <IconsWorker icon="circuleAddF" color="#fff" width="20"/>
            <span>{{state.data.name}}</span>
          </div>
          <div class="infoCel" @click="openInstagramLink();" style="cursor:pointer;">
            <IconsWorker icon="instagramF" color="#fff" width="20"/>
            <span>{{state.data.instagram}}</span>
          </div>
          <div class="infoCel" style="cursor:pointer;">
            <IconsWorker icon="clockF" color="#fff" width="20"/>
            <span>منذ <TimeAbout :time-stamp="state.data.date"/></span>
          </div>
          <div class="ratingBar">
            <RatingBar :init-rate="state.data.rating" :read-only="true" icons-width="15"/>
          </div>
          <div class="requiredPermissionTag" v-if="state.data.is_need_permission">تتطلب تصريح</div>
          <div class="requiredPermissionTag" v-if="!state.data.is_need_permission" :style="{background: 'red'}">لا يتطلب تصريح</div>
        </div>
        <div class="googleMapsLogo" @click="openGoogleMapsLink();">
          <img src="../assets/images/googleMapsLogo.png"/>
          <span>عرض على الخريطة</span>
        </div>

      </div>

      <div class="discreption" v-if="!state.isLoading">
        <div class="lable">الوصف:</div>
        <span>{{ state.data.discreption }}</span>
      </div>

      <div class="allPhotos">
          <DuPhotoViewer v-for="(photo, index) in state.photos" v-show="!state.isShowingAllImage && index < 8" :thumb="createPhotoLink(photo, 80, 80)"  :fullImage="createPhotoLink(photo)" width="80" height="80" :listOfLinks="getListOfLinks()"/>
          <DuPhotoViewer v-for="(photo, index) in state.photos" v-if="state.isShowingAllImage" :thumb="createPhotoLink(photo, 80, 80)"  :fullImage="createPhotoLink(photo)" width="80" height="80" :listOfLinks="getListOfLinks()"/>
          <div class="morePhotosSign" v-if="!state.isLoading && !state.isShowingAllImage && state.photos.length > 8" @click="showAllPhotos()">
            +{{state.photos.length - 8}}
          </div>
      </div>
      <div class="commentsSectionLable">التعليقات</div>
      <div class="commentsSection" v-if="!state.isLoading">
        <DuList  type="comments" :exstra-parameters="{...getCommentsExstraParameters()}"/>
        <div class="addNewCommentArea">
          <div class="commenterInfo">
            <DuInput
                placeholder="الاسم"
                type="text"
                type-error-message="هذا الاسم غير صالح"
                :is-required="true"
                is-required-error-message="رجاء قم بكاتبة اسمك"
                @input="(e) => state.commenterName = e.value"
                :get-ref="(e) => state.formRefs.push(e)"
            />
            <DuInput
                placeholder="البريد الإلكتروني"
                type="text"
                type-error-message="هذا البريد غير صالح"
                :is-required="true"
                is-required-error-message="رجاء قم بكاتبة بريدك الإلكتروني"
                @input="(e) => state.commenterEmail = e.value"
                :get-ref="(e) => state.formRefs.push(e)"
            />
          </div>
          <div class="addCommentTextArea">
            <DuInput
                placeholder="اكتب تعليقك هنا"
                type="textarea"
                type-error-message="هذا التعليق غير صالح"
                :is-required="true"
                is-required-error-message="رجاء قم بكاتبة التعليق"
                @input="(e) => state.comment = e.value"
                :get-ref="(e) => state.formRefs.push(e)"
            />
          </div>
          <div class="sendCommentArea">
            <RatingBar :init-rate="10" :onSet="setRating"/>
            <DuButton text="ارسال" background="#CE0086" @click="send()"/>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import IconsWorker from "../components/IconsWorker";
import DuPhotoViewer from "../components/DuPhotoViewer";
import {onMounted, reactive} from "vue";
import { useRoute } from 'vue-router';
import AjaxWorker from "../helpers/AjaxWorker";
import DuInput from "../components/DuInput";
import DuButton from "../components/DuButton";
import RatingBar from "../components/RatingBar";
import FixedNotification from "../components/plugins/fixedNotifications/FixedNotification";
import DuList from "../components/DuList";
import BrowserHelper from "../helpers/BrowserHelper";
import TimeAbout from "../components/fragments/TimeAbout";
export default {
  name: "PlaceInfo",
  components: {TimeAbout, DuList, RatingBar, DuButton, DuInput, DuPhotoViewer, IconsWorker, Footer, TopMenu},
  setup() {
    const route = useRoute();
    const state = reactive({
      isLoading: true,
      date: {},
      id: null,
      photos: null,
      formRefs: [],
      commenterName: '',
      commenterEmail: '',
      comment: '',
      rating: 10,
      isSendingComment: false,
      isShowingAllImage: false,
    });
    const getData = async () => {
      await new AjaxWorker().request('/transactions.php?action=getItems', {
        id: state.id,
      }).then((res) => {
        console.log(res);
        if(res === "NOINTERNETCONNECTION") {

        } else {
          state.data = res.data[0];
          state.photos = state.data.photos.split(',');
          BrowserHelper.changeTitle(state.data.place_name);
        }
      });
      state.isLoading = false;
    }
    onMounted(() => {
      state.id = route.params.id;
      getData();
    });
    const createPhotoLink = (serial, width=null, height=null) => {
      let link = new AjaxWorker().url + '/media/' + serial;
      if(width !== null && height !== null) {
        link = link + '?w=' + width.toString();
      } else {
        link = link + "?w=900";
      }
      return link;
    }
    const send = () => {
      let isFormValid = true;
      state.formRefs.forEach((el) => {
        el.validateNow();
        if(!el.state.isValid) {
          isFormValid = false;
        }
      });
      if(isFormValid) {
        state.isSendingComment = true;
        new AjaxWorker().request('/transactions.php?action=addNewComment', {
          postId: state.id,
          comment: state.comment,
          name: state.commenterName,
          email: state.commenterEmail,
          rating: state.rating
        }).then((res) => {
          state.isSendingComment = false;

          console.log(res);

          if(res === "NOINTERNETCONNECTION") {
            FixedNotification.create({
              title: 'حدث خطاء أثناء الإتصال',
              decription: 'تأكد من إتصالك بالإنترنت',
              icon: 'wifiF',
              color: 'red',
              hideAfter: 3000
            });
          } else {
            if(res.state) {
              FixedNotification.create({
                title: 'تم إرسال تعليقك',
                decription: 'سنقوم بنشره حال مراجعته',
                icon: 'doneF',
                color: 'green',
                hideAfter: 3000
              });
              state.formRefs.forEach((el) => {
                el.state.innerValue = '';
              });
            } else {
              FixedNotification.create({
                decription: "خطاء غير معلوم",
                icon: "errorF",
                color: 'red',
                hideAfter: 3000
              });
            }
          }

        })
      }
    }
    const openGoogleMapsLink = () => {
      window.open(state.data["google_map_url"], '_blank').focus();
    }
    const openInstagramLink = () => {
      window.open("https://instagram.com/" + state.data["instagram"], '_blank').focus();
    }
    const getListOfLinks = () => {
      const links = [];
      state.photos.forEach((item, index) => {
        links.push({
          fullImage: new AjaxWorker().url + "/media/" + item + "?w=900",
          thumb: new AjaxWorker().url + "/media/" + item + "?w=100",
          id: index
        });
      });
      return links;
    }

    const showAllPhotos = () => {
      state.isShowingAllImage = true;
    }

    const setRating = (val) => state.rating = val;

    const getCommentsExstraParameters = () => {
      return { postId: state.id.toString(), isPosted: true };
    }

    return {
      openGoogleMapsLink,
      showAllPhotos,
      setRating,
      getListOfLinks,
      openInstagramLink,
      getCommentsExstraParameters,
      createPhotoLink,
      send,
      state,
    }
  }
}
</script>

<style scoped>
.placePage{
  background-color: #000;
  width: 100%;
  min-height: 100%;
  background-image: url("../assets/images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}



.infoSection{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  text-align: start;
}
.infoSection .thumb{
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  background-color: #000;
  border-radius: 10px;
}
.infoSection .infoSide{
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  color: #fff;
}
.infoSection .placeName{
  font-size: 18px;
  font-weight: bold;
}

.infoSection .infoCel{
  display: flex;
  flex-direction: row;
  align-items: start;
  font-size: 13px;
  font-weight: bold;
  gap: 10px;
  margin-top: 10px;
}

.infoSection .infoCel span{
  flex: 1;
}

.requiredPermissionTag{
  padding: 5px 10px;
  border-radius: 100px;
  background-color: green;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  width: max-content;
  margin-top: 20px;
}

.googleMapsLogo{
  place-self: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #fff;
  place-content: center;
  place-items: center;
  font-weight: bold;
  cursor: pointer;
  margin-inline-end: 20%;
}


.googleMapsLogo img{
  width: 70px;
}

.allPhotos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 40px;
}

.commentsSectionLable{
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  text-align: start;
  margin-top: 40px;
}
.commentsSection{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: available;
  padding: 20px;
  overflow: hidden;
  border-radius: 10px;
  background: rgba(44, 2, 71, 0.8);
}

.addNewCommentArea{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.addNewCommentArea .commenterInfo{
  width: available;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.addNewCommentArea .addCommentTextArea{
  width: available;
  display: flex;
  flex-direction: column;
}

.addNewCommentArea .sendCommentArea{
  width: available;
  display: flex;
  flex-direction: row;
  place-content: end;
  margin-top: 20px;
  gap: 20px;
}
.ratingBar{
  margin-top: 10px;
  place-self: start;
}

.morePhotosSign{
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
  font-weight: bold;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.discreption{
  font-size: 18px;
  line-height: 25px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: start;
  font-weight: bold;
}

.discreption .lable{
  margin-bottom: 10px;
  font-size: 21px;
}

.discreption span{
  white-space: pre-line;
  word-break: break-word;
}

@media only screen and (max-width: 600px) {
  .placePage {
    background-attachment: scroll;
  }
  .infoSection {
    flex-direction: column;
  }
  .infoSection .thumb{
    width: 100%;
    height: 150px;
  }
  .addNewCommentArea .commenterInfo{
    flex-direction: column;
    row-gap: 0px;
  }

  .discreption{
    font-size: 16px;
  }

  .discreption .lable{
    font-size: 18px;
  }
  .allPhotos{
    display: none;
  }
  .googleMapsLogo{
    margin-inline-end: 0%;
  }
}
</style>